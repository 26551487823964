<template>
  <div :class="['text-body-1', `${color}--text`, ellipsis ? 'ellipsis' : '']">
    <slot></slot>
    <span v-if="required" class="text-body-1 primary--text">*</span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      default: "black"
    },
    required: {
      type: Boolean,
      default: false
    },
    ellipsis: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>